import React from 'react'
import Layout from '../../../components/employerDashboard/layout'
import SavedResumesComponent from '../../../components/employerDashboard/resume/savedResumes'

const SavedResumes = ({ location }) => {
  return (
    <Layout title="Saved Resumes | MedReps.com" location={location}>
      <SavedResumesComponent />
    </Layout>
  )
}
export default SavedResumes
