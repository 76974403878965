import React, { useEffect, useState, useMemo } from 'react'
import axios from 'axios'
import { Spin, Table, message, Input } from 'antd'
import { displayValueHandler } from '../../../functions'
import EmployerResumePreview from '../../Modals/employerResumePreview'
import { strapiURL } from '../../../config'
import cookie from 'react-cookies'

const SavedResumes = ({ userProfile, setUserProfile }) => {
  const [resumes, setResumes] = useState([])
  const [backupData, setBackupData] = useState([])
  const [resumeUnlockLoading, setResumeUnlockLoading] = useState(null)
  const [selectCandidate, setSelectCandidate] = useState(null)
  const [prevOpen, setPrevOpen] = useState(false)
  const [selectCandidateIndex, setSelectCandidateIndex] = useState(0)

  useEffect(() => {
    if (userProfile?.id) {
      handleLoadSavedProfiles()
    }
  }, [userProfile])

  const handleLoadSavedProfiles = async () => {
    try {
      const result = await axios.get(
        strapiURL +
          `/employer-saved-resumes/${userProfile?.id}/getSavedProfiles`
      )

      setResumes(getResponse(result?.data?.map(item => item.candidate_profile)))
      setBackupData(
        getResponse(result?.data?.map(item => item.candidate_profile))
      )
    } catch (error) {}
  }

  const getResponse = profileData => {
    const profileList = profileData.map(item => {
      const city = item.location?.location?.city
      const state = item.location?.location?.state
      let address = ''
      if (city && state) {
        address = city + ', ' + state
      } else if (city && !state) {
        address = city
      } else if (!city && state) {
        address = state
      } else {
        address = 'Not Specified'
      }
      return {
        ...item,
        address,
      }
    })
    return profileList
  }

  const saveResumeHandler = async (record, status) => {
    let currentUser = cookie.load('user')
    try {
      const result = await axios.post(
        strapiURL +
          `/employer-saved-resumes/${userProfile?.id}/setSavedProfiles`,
        {
          candidate_profile: record.id,
          saved_by: currentUser?.user?.id,
          saveStatus: status,
        }
      )

      setResumes(getResponse(result?.data?.map(item => item.candidate_profile)))
      setBackupData(
        getResponse(result?.data?.map(item => item.candidate_profile))
      )
    } catch (error) {}
  }

  const viewResume = async (candidateId, index) => {
    setSelectCandidate(candidateId)
    setSelectCandidateIndex(index)
    setPrevOpen(true)
  }

  const resumeCols = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: '20%',
      key: 'name',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            <div
              className="h-8 w-8 rounded-full bg-no-repeat bg-cover"
              style={{
                backgroundImage: `url(${
                  record?.image?.url || '/images/general-avatar-icon.png'
                })`,
              }}
            />
            <p className="text-black font-sans font-semibold mb-0 text-last ml-2">
              {record.name}
            </p>
          </div>
        )
      },
    },
    {
      title: 'Function',
      dataIndex: 'function',
      width: '20%',
      key: 'function',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-normal mb-0 text-last">
            {record.job_functions
              ? Array.isArray(record.job_functions)
                ? record.job_functions?.length
                  ? record.job_functions.map((item, index) => {
                      return (
                        displayValueHandler(item) +
                        (index !== record.job_functions.length - 1 ? ', ' : '')
                      )
                    })
                  : 'Not Specified'
                : displayValueHandler(record.job_functions)
              : 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Industry',
      dataIndex: 'industry',
      width: '20%',
      key: 'industry',
      render: (text, record, index) => {
        return (
          <p className="text-black font-sans font-normal mb-0 text-last">
            {record.industry
              ? displayValueHandler(record.industry)
              : record.industries?.length
              ? record.industries.map((item, index) => {
                  return (
                    displayValueHandler(item) +
                    (index !== record.industries.length - 1 ? ', ' : '')
                  )
                })
              : 'Not Specified'}
          </p>
        )
      },
    },
    {
      title: 'Location',
      dataIndex: 'location',
      width: '20%',
      key: 'location',
      render: (text, record, index) => {
        return (
          <div className="flex items-center text-black font-sans font-normal mb-0 text-last">
            <svg
              className="mr-1"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.95">
                <path
                  d="M7.43945 3.73804C7.06861 3.73804 6.7061 3.848 6.39776 4.05403C6.08942 4.26006 5.84909 4.55289 5.70718 4.89551C5.56527 5.23812 5.52813 5.61512 5.60048 5.97883C5.67283 6.34254 5.8514 6.67664 6.11363 6.93886C6.37585 7.20108 6.70995 7.37966 7.07366 7.45201C7.43737 7.52436 7.81437 7.48722 8.15699 7.34531C8.4996 7.2034 8.79243 6.96307 8.99846 6.65473C9.20449 6.34639 9.31445 5.98388 9.31445 5.61304C9.31389 5.11593 9.11616 4.63934 8.76465 4.28784C8.41315 3.93633 7.93656 3.7386 7.43945 3.73804ZM7.43945 6.55054C7.25403 6.55054 7.07278 6.49555 6.91861 6.39254C6.76444 6.28952 6.64427 6.14311 6.57332 5.9718C6.50236 5.8005 6.48379 5.612 6.51997 5.43014C6.55614 5.24828 6.64543 5.08124 6.77654 4.95012C6.90765 4.81901 7.0747 4.72972 7.25656 4.69355C7.43841 4.65738 7.62691 4.67594 7.79822 4.7469C7.96953 4.81786 8.11594 4.93802 8.21896 5.09219C8.32197 5.24636 8.37695 5.42762 8.37695 5.61304C8.37667 5.86159 8.2778 6.09988 8.10205 6.27563C7.9263 6.45139 7.68801 6.55025 7.43945 6.55054Z"
                  fill="black"
                />
                <path
                  d="M11.0416 2.00733C10.1508 1.11672 8.96165 0.587656 7.70367 0.522257C6.44569 0.456859 5.20808 0.859758 4.22969 1.6532C3.25131 2.44663 2.6015 3.57436 2.4057 4.81872C2.20989 6.06309 2.48196 7.33588 3.1694 8.39144L6.7116 13.8293C6.79043 13.9503 6.89821 14.0497 7.02517 14.1186C7.15213 14.1874 7.29426 14.2234 7.43867 14.2234C7.58309 14.2234 7.72522 14.1874 7.85218 14.1186C7.97914 14.0497 8.08692 13.9503 8.16575 13.8293L11.7081 8.39144C12.3459 7.41225 12.6278 6.24356 12.5065 5.08126C12.3852 3.91895 11.868 2.83367 11.0416 2.00733ZM10.9226 7.87971L7.43869 13.2279L3.95479 7.87971C2.88839 6.24263 3.11711 4.05181 4.49863 2.67023C4.88472 2.28413 5.34308 1.97785 5.84754 1.7689C6.35199 1.55994 6.89267 1.45239 7.43869 1.45239C7.98471 1.45239 8.52539 1.55994 9.02984 1.7689C9.5343 1.97785 9.99266 2.28413 10.3787 2.67023C11.7603 4.05181 11.989 6.24263 10.9226 7.87971Z"
                  fill="black"
                />
              </g>
            </svg>
            <div className="flex-1">{record?.address}</div>
          </div>
        )
      },
    },
    {
      title: 'Action',
      dataIndex: 'id',
      width: '10%',
      key: 'id',
      render: (text, record, index) => {
        return (
          <div className="flex items-center">
            <span
              onClick={() => {
                viewResume(record.id, index)
              }}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              View
            </span>
            <div className="h-4 w-px bg-merlot mx-2" />
            <p className=" mx-2 inline-block bg-[#ddeddf] text-[#059913] text-location text-last font-sans mb-0 px-2 rounded">
              Saved
            </p>
            <span
              onClick={() => saveResumeHandler(record, false)}
              className="font-sans text-last text-merlot mb-0 cursor-pointer"
            >
              Unsave
            </span>
            <div
              className={
                resumeUnlockLoading === parseInt(record.objectID)
                  ? 'opacity-100'
                  : 'opacity-0'
              }
            >
              <Spin className="action-loader ml-2" />
            </div>
          </div>
        )
      },
    },
  ]

  const searchHandler = value => {
    let filteredItems = backupData.filter(
      item => item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
    )
    setResumes(filteredItems)
  }

  const handleNextProfile = async () => {
    if (selectCandidateIndex < resumes.length - 1) {
      setSelectCandidateIndex(selectCandidateIndex + 1)
      setSelectCandidate(resumes[selectCandidateIndex + 1].id)
    }
  }

  const handlePrevProfile = async () => {
    if (selectCandidateIndex > 0) {
      setSelectCandidateIndex(selectCandidateIndex - 1)
      setSelectCandidate(resumes[selectCandidateIndex - 1].id)
    }
  }

  const isPrevDisable = useMemo(() => {
    if (selectCandidateIndex <= 0) {
      return true
    }

    return false
  }, [selectCandidateIndex])

  const isNextDisable = useMemo(() => {
    if (selectCandidateIndex + 1 >= resumes.length) {
      return true
    }

    return false
  }, [selectCandidateIndex, resumes])

  const isSavedProfile = useMemo(() => {
    if (selectCandidate) {
      if (
        (backupData || []).findIndex(item => item.id == selectCandidate) >= 0
      ) {
        return true
      }
      return false
    }
    return false
  }, [selectCandidate, backupData])

  return (
    <div className="mt-6 lg:mt-0">
      <h1 className="font-playfair text-headingThree font-normal text-merlot">
        Saved Resumes
      </h1>
      <div className="w-[30%]">
        <Input
          onChange={event => searchHandler(event.target.value)}
          className="py-3 rounded-md"
          placeholder="Search Saved Resumes"
        />
      </div>
      <div className="rounded-lg bg-white shadow-profileCard mt-8">
        <div className="flex px-4 py-4 border-b border-bgSecondaryButton justify-between">
          <div className="flex items-center">
            <img src="/icons/icon-application.svg" className="mb-0" />
            <h1 className="font-playfair text-xl font-normal mb-0 ml-4">
              Results ({resumes?.length || 0})
            </h1>
          </div>
        </div>
        <Table
          className="hide-table-header-background hide-table-header-cols-border table-with-pagination table-with-checkbox w-full"
          scroll={{ x: 'min-content' }}
          columns={resumeCols}
          dataSource={resumes}
          bordered={false}
          rowSelection={false}
          pagination={{
            defaultCurrent: 1,
            defaultPageSize: 20,
            showSizeChanger: false,
          }}
        />
      </div>
      <EmployerResumePreview
        modalOpen={prevOpen}
        setModalOpen={setPrevOpen}
        candidateId={selectCandidate}
        userProfile={userProfile}
        onNextProfile={handleNextProfile}
        onPrevProfile={handlePrevProfile}
        isPrevDisable={isPrevDisable}
        isNextDisable={isNextDisable}
        saveProfile={data => saveResumeHandler(data, true)}
        unsaveProfile={data => saveResumeHandler(data, false)}
        isSaved={isSavedProfile}
      />
    </div>
  )
}

export default SavedResumes
